import { useScrollLock } from '@brand/utils';
import cn from 'classnames';
import { SimpleImage } from 'components/simple-image';
import { Spinner } from 'components/ui-kit';
import loader from 'public/images/loader.gif';
import styles from './app-loader.module.scss';
export type TAppLoaderProps = {
  isShow: boolean;
};
export const AppLoader = ({ isShow }: TAppLoaderProps): JSX.Element => {
  useScrollLock(isShow);
  return (
    <div className={cn(styles.container, { [styles.isShow]: isShow })}>
      <div>
        <SimpleImage
          alt="Универмаг"
          src={loader}
          width={550}
          height={410}
          priority
        />
        <div className={styles.spinner}>
          <Spinner size={40} />
        </div>
      </div>
    </div>
  );
};
