import { FormikHandlers } from 'formik/dist/types';
import { ReactElement } from 'react';
import { FormTextField } from './form-text-field.component';

type TFormPhoneFieldProps<FromValues> = {
  name: keyof FromValues;
  label: string;
  accessor?: ReactElement;
  isDisabled?: boolean;
  invalidMessage?: string;
  validate?: (value: FromValues[keyof FromValues]) => string | undefined;
  onBlur?: FormikHandlers['handleBlur'];
  dataAt?: string;
  hasNotMask?: boolean;
};

export function FormPhoneField<FromValues = object>(
  props: TFormPhoneFieldProps<FromValues>,
): JSX.Element {
  const { hasNotMask, ...restProps } = props;
  return (
    <FormTextField
      {...restProps}
      mask={hasNotMask ? undefined : '+7(999)999-99-99'}
    />
  );
}
