'use client';
import { ProfileService, TProductResponse } from 'lib';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { useMemo } from 'react';

export const useFilterProductsByAccount = (
  products: Array<TProductResponse> = [],
): Array<TProductResponse> => {
  const { profile } = useUserInfoContext();

  return useMemo(
    () =>
      products.filter((product: TProductResponse) => {
        return product.onlyEmployees
          ? profile && ProfileService.isTochkaProfile(profile)
          : true;
      }),
    [products, profile],
  );
};
