'use client';
import { HttpClientError } from '@brand/utils';
import { AppLoader } from 'components/app-loader';
import { IUserInfo, Profile, authService, profileService } from 'lib';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  type JSX,
} from 'react';
import useSWR from 'swr';

const noopPromise = (): Promise<void> => Promise.resolve();

type TPricePolicy = 'regular' | 'employee';
type TProvidedValue = {
  isLoading: boolean;
  isValidating: boolean;
  pricePolicy: TPricePolicy;
  profile?: Profile | null;
  updateProfile: (profile: Partial<Profile>) => Promise<void>;
  invalidateProfile: () => Promise<Profile | undefined | null | void>;
  logout: () => Promise<Profile | undefined | null | void>;
};

const Context = createContext<TProvidedValue>({
  isLoading: false,
  isValidating: false,
  profile: null,
  pricePolicy: 'regular',
  updateProfile: noopPromise,
  invalidateProfile: noopPromise,
  logout: noopPromise,
});

type TUserInfoProviderProps = {
  children: JSX.Element;
  userInfo?: IUserInfo | null;
};
export const useUserInfoContext = (): TProvidedValue => useContext(Context);

export const UserInfoProvider = ({
  children,
  userInfo,
}: TUserInfoProviderProps): JSX.Element => {
  const {
    data: profile = userInfo?.profile ?? null,
    mutate,
    isLoading,
    isValidating,
    error,
  } = useSWR<Profile | undefined | null, HttpClientError>(
    profileService.getProfile.name,
    () => profileService.getProfile(),
    {
      revalidateOnFocus: true,
      errorRetryCount: 0,
      onError: () => void mutate(null, { revalidate: false }),
    },
  );

  const invalidateProfile = useCallback(() => mutate(), [mutate]);

  const updateProfile = useCallback(
    async (updateProfileDTO: Partial<Profile>) => {
      await profileService
        .updateProfile(updateProfileDTO)
        .then(invalidateProfile);
    },
    [invalidateProfile],
  );

  const logout = useCallback(() => {
    authService.cleanToken();
    return mutate(null);
  }, [mutate]);

  useEffect(() => {
    authService.on('logout', () => void logout());
  }, [logout]);

  const value: TProvidedValue = {
    profile: profile,
    logout,
    pricePolicy:
      profile?.role === 'employee' ? 'employee' : ('regular' as TPricePolicy),
    isLoading: !profile && !error,
    isValidating: isLoading || isValidating,
    updateProfile,
    invalidateProfile,
  };

  return (
    <Context.Provider value={value}>
      <>
        {children}
        <AppLoader isShow={isLoading && !isValidating} />
      </>
    </Context.Provider>
  );
};
