import cn from 'classnames';
import React, { PropsWithChildren } from 'react';
import styles from './visibility.module.scss';

type Breakpoint = 's' | 'sm' | 'm' | 'l' | 'xl';
export type TVisibilityProps = {
  /**
   * От какого разрешения элемент будет видимым
   * @default s
   */
  visible?: Breakpoint;
  /**
   * От какого разрешения элемент будет скрыт
   */
  hidden?: Breakpoint;
  className?: string;
};

/**
 * @param children
 * @param visible
 * @param hidden
 * @param className
 * @constructor
 * @example
 * <>
 *   <Visibility hidden="m">
 *     Виден от экрана "S" до экрана "m"
 *   </Visibility>
 *   <Visibility visible="m" hidden="xl">
 *      Виден от экрана "m" до экрана "xl"
 *   </Visibility>
 *   <Visibility visible="l">
 *      Виден от экрана "l"
 *   </Visibility>
 *   <Visibility visible="xl">
 *      Виден от экрана "xl"
 *   </Visibility>
 * </>
 */
export const Visibility = ({
  children,
  visible = 's',
  hidden,
  className,
}: PropsWithChildren<TVisibilityProps>): JSX.Element => {
  return (
    <div
      className={cn(
        className,
        styles.visible,
        styles[`visible-${visible ?? ''}`],
        styles[`hidden-${hidden ?? 'default'}`],
      )}
    >
      {children}
    </div>
  );
};
