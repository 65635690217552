import { Spacer } from 'components/ui-kit';
import { useMedia } from 'hooks/use-media-query';
import React, { useMemo } from 'react';
import { Breakpoint } from '../../../../_shared';

export const HomepageSectionSpacer = (): JSX.Element => {
  const breakpoint = useMedia();
  const spacerSize = useMemo(
    () => (breakpoint > Breakpoint.MEDIUM_SCREEN ? 30 : 20),
    [breakpoint],
  );

  return <Spacer size={spacerSize} />;
};
