import { Field, FieldProps } from 'formik';
import React from 'react';
import { RadioButton, TRadioButtonOption } from '../ui-kit';

type TFormTextFieldProps<FromValues> = {
  name: keyof FromValues;
  options: Array<TRadioButtonOption>;
  hasGap?: boolean;
  className?: string;
  size?: 'l' | 'm';
};

export function FormRadioButton<FromValues = object>(
  props: TFormTextFieldProps<FromValues>,
): JSX.Element {
  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps<string>) => (
        <RadioButton
          size={props.size}
          className={props.className}
          selected={field.value}
          onChange={(value: string) => form.setFieldValue(field.name, value)}
          options={props.options}
          hasGap={props.hasGap}
        />
      )}
    </Field>
  );
}
