import cn from 'classnames';
import { SimpleImage } from 'components/simple-image';
import { Button, Container, Spacer } from 'components/ui-kit';
import { useMedia } from 'hooks';
import { StaticImageData } from 'next/image';
import React from 'react';
import styles from './homepage-banner.module.scss';
import { Breakpoint } from '../../../../_shared';

type TSizesMap = {
  s: string | StaticImageData;
  l?: string | StaticImageData;
};

type THomepageBannerProps = {
  title: JSX.Element;
  link?: string;
  buttonText?: string;
  backgroundImgUrlMap: TSizesMap;
  subtitle?: JSX.Element;
  buttonVariant?: 'white' | 'secondary';
};

const BreakpointToSize: Record<number, 's' | 'l'> = {
  [Breakpoint.LARGE_SCREEN]: 'l',
  [Breakpoint.SMALL_SCREEN]: 's',
};

export const HomepageBanner = ({
  buttonText,
  link,
  subtitle,
  title,
  backgroundImgUrlMap,
  buttonVariant = 'white',
}: THomepageBannerProps): JSX.Element => {
  const breakpoint = useMedia();

  const image =
    backgroundImgUrlMap[BreakpointToSize[breakpoint]] ??
    backgroundImgUrlMap.l ??
    backgroundImgUrlMap.s;

  return (
    <section className={cn(styles.banner, styles.main)}>
      <div className={styles.bgImage}>
        <SimpleImage
          alt=""
          src={image}
          layout="fill"
          objectFit="cover"
          placeholder="blur"
        />
      </div>
      <Container>
        <div className={styles.homepageBanner}>
          {title}
          {subtitle && (
            <>
              <Spacer size={12} />
              {subtitle}
            </>
          )}
          <Spacer size={32} />
          {!!buttonText && (
            <Button
              href={link}
              type="inner-link"
              variant={buttonVariant}
              className={styles.button}
              size="l"
            >
              {buttonText}
            </Button>
          )}
        </div>
      </Container>
    </section>
  );
};
