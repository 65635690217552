import { memo } from 'react';
import Marquee from 'react-fast-marquee';
import styles from './homepage-marquee.module.scss';

const defaultTitle = 'точка х BEAT FILM \uff0d точка х ';

interface IHomepageMarqueeProps {
  /*
   * Задает бегающую строку
   */
  title?: string;
}

export const HomepageMarquee = memo(function HomepageMarquee({
  title = defaultTitle,
}: IHomepageMarqueeProps) {
  const marqueeTitle = title.repeat(10);

  return (
    <Marquee speed={200} gradient={false}>
      <span className={styles.marqueeTitle}>{marqueeTitle}</span>
    </Marquee>
  );
});
