import {
  Button,
  TButtonProps,
} from 'components/ui-kit/button/button.component';
import React, { memo } from 'react';
import styles from './button-with-width.module.scss';

type TButtonWithWidthProps = {
  /*
   * Задает кнопку с определенной шириной
   */
  width: number;
} & TButtonProps;

export const ButtonWithWidth = memo(function ButtonWithWidth(
  props: TButtonWithWidthProps,
): JSX.Element {
  return (
    <div
      className={styles.buttonWithWidth}
      style={{ width: `${props.width}px` }}
    >
      <Button {...props} />
    </div>
  );
});
