import cn from 'classnames';
import React from 'react';
import styles from './checkbox.module.scss';

type TCheckboxProps = {
  label?: string | JSX.Element;
  isInvalid?: boolean;
} & Omit<React.HTMLProps<HTMLInputElement>, 'type' | 'id' | 'label'>;

export const Checkbox = ({
  label,
  className,
  isInvalid,
  ...props
}: TCheckboxProps): JSX.Element => {
  return (
    <label
      className={cn(styles.container, className, {
        [styles.invalid]: isInvalid,
      })}
    >
      <input {...props} className={styles.input} type="checkbox" />
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.checkbox}
      >
        <rect
          className={styles.checkboxBackground}
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="1.5"
          fill="#333333"
        />
        <path
          className={styles.checkboxArrow}
          d="M4 7.5L7 10.5L12.5 5"
          stroke="white"
          strokeWidth="2"
        />
        <rect
          className={styles.checkboxOuter}
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="1.5"
          stroke="#333333"
        />
      </svg>
      {label}
    </label>
  );
};
