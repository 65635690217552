import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/public/images/banners/polka-lairs/back_mob.jpeg");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/public/images/banners/polka-lairs/back_tablet.jpeg");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/public/images/banners/polka-lairs/back.jpeg");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/public/images/banners/two-zero/back_mob.jpg");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/public/images/banners/two-zero/back_tablet.jpg");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/public/images/banners/two-zero/back.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["ScriptGA","ScriptVK","ScriptYaMetrika"] */ "/builds/brand/svc/ishop/ishop-frontend/src/_shared/scripts/metrics.script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductsCarousel"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/products-carousel/products-carousel.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/simple-image/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/accordion/accordion.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/button/button.module.scss");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/card/card.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/carousel/carousel.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/center/center.module.scss");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/clickable-card/clickable-card.module.scss");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/column/column.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/container/container.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/divider/divider.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["DrawerBody"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/drawer/drawer-body.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DrawerHeader"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/drawer/drawer-header.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TypicalDrawer"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/drawer/drawer-typical.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Drawer"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/drawer/drawer.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/header/header.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["InputPassword"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/input-password/input-password.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Input"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/input/input.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/link/link.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["RadioButton"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/radio-button/radio-button.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/row/row.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Select"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/select/select.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/spinner/spinner.module.scss");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/spacer/spacer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["TextArea"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/text-area/text-area.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/typo/typo.module.scss");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/toaster/toaster.module.scss");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/icon-button/icont-button.module.scss");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/visibility/visibility.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["YouTubeVideo"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ui-kit/youtube-video/youtube-video.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/modules/homepage/banners/polka-lairs/polka-lairs-banner.module.scss");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/modules/homepage/banners/two-zero/two-zero-banner.module.scss");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/modules/homepage/components/index.ts");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/modules/homepage/homepage.module.scss");
