import cn from 'classnames';
import Image, { ImageProps } from 'next/image';
import Placeholder from 'public/images/image-placeholder.png';
import { ForwardedRef, forwardRef } from 'react';
import styles from './simple-image.module.scss';

/**
 * !Пока закоментил это добро,
 * !выбрасываем undefined для некоторых изображений
 */

// type TStaticRequireImage = {
//   default: StaticImageData;
// };
// type TStaticImport = TStaticRequireImage | StaticImageData;
// const getHashedSrc = (src: string): string => {
//   return src + `?_dc=${process.env.HASH_BUILD}`;
// };

/**
 * Добавление хеша в путь до картинки
 */
// const getHashedImage = (
//   image: TStaticImport | string,
// ): TStaticImport | string => {
//   if (typeof image === 'string') {
//     return getHashedSrc(image);
//   }

//   if (isImportedImage(image)) {
//     const srcImageObject = { ...image };

//     srcImageObject.src = getHashedSrc(image.src);
//     return srcImageObject;
//   }

//   if (isDefaultImportedImage(image)) {
//     const srcImageObject = { ...image, default: { ...image.default } };

//     srcImageObject.default.src = getHashedSrc(image.default.src);
//     return srcImageObject;
//   }

//   return image;
// };

// const isImportedImage = (image: unknown): image is StaticImageData => {
//   return !!image && typeof image === 'object' && !('default' in image);
// };

// const isDefaultImportedImage = (
//   image: unknown,
// ): image is TStaticRequireImage => {
//   return !!image && typeof image === 'object' && 'default' in image;
// };

export type TSimpleImageProps = Omit<ImageProps, 'src'> & {
  src: ImageProps['src'] | undefined;
  layout?: 'responsive' | 'fill' | 'fixed';
  objectFit?: 'cover' | 'contain';
  objectPosition?: 'center' | 'top' | 'bottom' | 'left';
};

export const SimpleImage = forwardRef(
  (
    {
      layout = 'fill',
      objectFit = 'cover',
      objectPosition = 'center',
      className,
      ...props
    }: TSimpleImageProps,
    ref: ForwardedRef<HTMLImageElement | null>,
  ): JSX.Element => {
    const classNames = cn(
      className,
      styles[`objectFit_${objectFit ?? ''}`],
      styles[`objectPosition_${objectPosition ?? ''}`],
      styles[`layout_${layout ?? ''}`],
    );

    if (!props.src) {
      return (
        <Image {...props} ref={ref} className={classNames} src={Placeholder} />
      );
    }

    return (
      <Image {...props} ref={ref} className={classNames} src={props.src} />
    );
  },
);
