'use client';

import { ProductCard } from 'components/product-card';
import { Carousel } from 'components/ui-kit';
import { useFilterProductsByAccount } from 'hooks/use-filter-products-by-account';
import { TProductResponse, productService } from 'lib';
import Link from 'next/link';
import { memo, useState } from 'react';
import styles from './products-carousel.module.scss';

const breakpoints = {
  320: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 30,
  },
};

type TProductsCarouselProps = {
  products: Array<TProductResponse>;
};

export const ProductsCarousel = memo(function ProductsCarousel({
  products,
}: TProductsCarouselProps): JSX.Element {
  const [offsetControlY, setOffsetControlY] = useState(0);
  const availableProducts = useFilterProductsByAccount(
    productService.filterProductsByAvailability(products),
  );

  const handleChangeProductCardImageHeight = (imageHeight: number): void => {
    // В данной карусели кнопки управления свайпером позиционируются относительно изображения
    const offsetY = imageHeight / 2;

    setOffsetControlY(offsetY);
  };

  return (
    <Carousel<TProductResponse>
      data={availableProducts}
      breakpoints={breakpoints}
      offsetYControl={offsetControlY}
      getKey={(product: TProductResponse) => product.slug}
      renderSlide={(product: TProductResponse) => (
        <Link
          className={styles.productWrapper}
          href={`/product/${product.slug}`}
        >
          <ProductCard
            product={product}
            isDescriptionCentered
            onChangeImageHeight={handleChangeProductCardImageHeight}
          />
        </Link>
      )}
    />
  );
});
