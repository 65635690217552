import { Field, FieldProps } from 'formik';
import { FormikHandlers } from 'formik/dist/types';
import React, { ReactElement } from 'react';
import { Input } from '../ui-kit';

export type TFormTextFieldProps<FromValues> = {
  name: keyof FromValues;
  type?: 'text' | 'email' | 'password';
  label?: string;
  mask?: string | Array<string | RegExp>;
  maxLength?: number;
  accessor?: ReactElement;
  isDisabled?: boolean;
  validate?: (value: FromValues[keyof FromValues]) => string | undefined;
  invalidMessage?: string;
  onBlur?: FormikHandlers['handleBlur'];
  isAutocomplete?: boolean;
  dataAt?: string;
  placeholder?: string;
  className?: string;
};

export function FormTextField<FromValues = object>({
  type = 'text',
  isAutocomplete = true,
  ...props
}: TFormTextFieldProps<FromValues>): JSX.Element {
  return (
    <Field name={props.name} validate={props.validate}>
      {({ field, meta, form }: FieldProps<string>) => (
        <Input
          className={props.className}
          placeholder={props.placeholder}
          dataAt={props.dataAt}
          name={field.name}
          value={field.value}
          onSyntheticChange={field.onChange}
          onChange={(value: string) => form.setFieldValue(field.name, value)}
          onBlur={field.onBlur}
          type={type}
          label={props.label}
          mask={props.mask}
          isDisabled={props.isDisabled}
          accessor={props.accessor}
          maxLength={props.maxLength}
          isInvalid={!!(meta.touched && meta.error)}
          invalidMessage={props.invalidMessage}
          isAutocomplete={isAutocomplete}
        />
      )}
    </Field>
  );
}
