import { Field, FieldProps } from 'formik';
import React from 'react';
import { InputPassword } from '../ui-kit';

type TFormPasswordFieldProps<FromValues> = {
  name: keyof FromValues;
  label: string;
  validate?: (value: FromValues[keyof FromValues]) => string | undefined;
  invalidMessage?: string;
  dataAt?: string;
};

export function FormPasswordField<FromValues = object>(
  props: TFormPasswordFieldProps<FromValues>,
): JSX.Element {
  return (
    <Field name={props.name} validate={props.validate}>
      {({ field, meta }: FieldProps<string>) => (
        <InputPassword
          dataAt={props.dataAt}
          name={field.name}
          value={field.value}
          label={props.label}
          isInvalid={!!(meta.touched && meta.error)}
          invalidMessage={props.invalidMessage}
          onSyntheticChange={field.onChange}
          onChange={() => void 0}
          onBlur={field.onBlur}
        />
      )}
    </Field>
  );
}
