import { FormikHandlers } from 'formik/dist/types';
import { ReactElement } from 'react';
import { FormTextField } from './form-text-field.component';

type TFormPostalCodeFieldProps<FromValues> = {
  name: keyof FromValues;
  label: string;
  accessor?: ReactElement;
  isDisabled?: boolean;
  invalidMessage?: string;
  validate?: (value: FromValues[keyof FromValues]) => string | undefined;
  onBlur?: FormikHandlers['handleBlur'];
  isNotMasked?: boolean;
};

export function FormPostalCodeField<FromValues = object>(
  props: TFormPostalCodeFieldProps<FromValues>,
): JSX.Element {
  const { isNotMasked, ...restProps } = props;
  return (
    <FormTextField
      {...restProps}
      mask={isNotMasked ? undefined : '999-999'}
      isAutocomplete={false}
    />
  );
}
