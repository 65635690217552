import { Field, FieldProps } from 'formik';
import React, { ReactElement } from 'react';
import { TextArea } from '../ui-kit';

type TFormTextAreaFieldProps<FromValues> = {
  name: keyof FromValues;
  label: string;
  maxLength?: number;
  size?: number;
  accessor?: ReactElement;
  isDisabled?: boolean;
  validate?: (value: FromValues[keyof FromValues]) => string | undefined;
};

export function FormTextAreaField<FromValues = object>({
  ...props
}: TFormTextAreaFieldProps<FromValues>): JSX.Element {
  return (
    <Field name={props.name} validate={props.validate}>
      {({ field, meta, form }: FieldProps<string>) => (
        <TextArea
          name={field.name}
          size={props.size}
          value={field.value}
          onChange={(value: string) => form.setFieldValue(field.name, value)}
          label={props.label}
          isDisabled={props.isDisabled}
          accessor={props.accessor}
          maxLength={props.maxLength}
          isInvalid={!!(meta.touched && meta.error)}
        />
      )}
    </Field>
  );
}
