import { TOption } from '_shared';
import { Select } from 'components/ui-kit/select';
import { Field, FieldProps } from 'formik';
import React from 'react';

type TFormPasswordFieldProps<FromValues> = {
  name: keyof FromValues;
  options: Array<TOption>;
  label?: string;
  onInputChange?: (value: string | undefined) => void;
  isOpen?: boolean;
  isDisabled?: boolean;
  noOptionsMessage?: string;
  placeholder?: string;
  initValue?: TOption;
  isAutocomplete?: boolean;
};

export function FormSelectField<FromValues = object>(
  props: TFormPasswordFieldProps<FromValues>,
): JSX.Element {
  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps<TOption>) => {
        return (
          <Select
            name={field.name}
            isAutocomplete={props.isAutocomplete}
            label={props.label}
            onChange={(value: string | undefined) =>
              form.setFieldValue(field.name, value)
            }
            options={props.options}
            onInputChange={props.onInputChange}
            isOpen={props.isOpen}
            isDisabled={props.isDisabled}
            noOptionsMessage={props.noOptionsMessage}
            initValue={props.initValue ?? field.value}
            placeholder={props.placeholder}
          />
        );
      }}
    </Field>
  );
}
