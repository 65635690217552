import { Field, FieldProps } from 'formik';
import React from 'react';
import { Spacer, Typo } from '../ui-kit';
import { Checkbox } from '../ui-kit/checkbox';

type TFormTextFieldProps = {
  name: string;
  className?: string;
  label?: string | JSX.Element;
};

export const FormCheckbox = (props: TFormTextFieldProps): JSX.Element => (
  <Field type="checkbox" name={props.name}>
    {({ field, meta }: FieldProps) => (
      <>
        <Checkbox
          {...props}
          {...field}
          isInvalid={meta.touched && !!meta.error}
        />
        {meta.touched && !!meta.error && (
          <>
            <Spacer size={8} />
            <Typo color="alert" variant="xm">
              {meta.error}
            </Typo>
          </>
        )}
      </>
    )}
  </Field>
);
