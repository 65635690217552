import { noop } from '@brand/utils';
import cn from 'classnames';
import { SimpleImage, TSimpleImageProps } from 'components/simple-image';
import { Column, Header, Row, Spacer } from 'components/ui-kit';
import { ButtonWithWidth } from 'components/ui-kit/button-with-width';
import { Visibility } from 'components/ui-kit/visibility';
import { StaticImageData } from 'next/image';
import Link from 'next/link';
import catAccessories from 'public/images/pages/home/accesories.jpeg';
import catAccessoriesMob from 'public/images/pages/home/accesories_mob.jpg';
import catHome from 'public/images/pages/home/for_home.jpeg';
import catHomeMob from 'public/images/pages/home/for_home_mob.jpg';
import catCloth from 'public/images/pages/home/wears.jpeg';
import catClothMob from 'public/images/pages/home/wears_mob.jpg';
import { memo } from 'react';
import styles from './homepage-categories.module.scss';

type Category = {
  url: string;
  img: StaticImageData;
  objectPosition?: TSimpleImageProps['objectPosition'];
  objectPositionMob?: TSimpleImageProps['objectPosition'];
  imgMobile: StaticImageData;
  title: string;
};

const categories: Array<Category> = [
  {
    url: '/odezhda',
    img: catCloth,
    imgMobile: catClothMob,
    objectPositionMob: 'top',
    title: 'Одежда',
  },
  {
    url: '/aksessuary',
    img: catAccessories,
    imgMobile: catAccessoriesMob,
    title: 'Аксессуары',
  },
  {
    url: '/dlya-doma-i-ofisa',
    img: catHome,
    imgMobile: catHomeMob,
    objectPositionMob: 'top',
    title: 'Для офиса и дома',
  },
];

export const HomepageCategories = memo(
  function HomepageCategories(): JSX.Element {
    const renderHomepageCategory = (
      category: Category,
      index: number,
    ): JSX.Element => {
      const { title, img, imgMobile, url } = category;
      const isFullWidth = index === 0;
      return (
        <Column key={title} span={{ s: 12, l: isFullWidth ? 12 : 6 }}>
          <Link className={cn(styles.mainCategory)} href={`/category${url}`}>
            <div className={styles.mainCategoryImage}>
              <Visibility hidden="m" className="h-100">
                <SimpleImage
                  alt=""
                  src={imgMobile}
                  layout="fill"
                  objectFit="cover"
                  placeholder="blur"
                  objectPosition={category.objectPositionMob}
                />
              </Visibility>
              <Visibility visible="m" className="h-100">
                <SimpleImage
                  alt=""
                  src={img}
                  layout="fill"
                  objectFit="cover"
                  placeholder="blur"
                  objectPosition={category.objectPosition}
                />
              </Visibility>
            </div>
            <div className={styles.mainCategoryText}>
              <Header color="white" level={2} align="center">
                {title}
              </Header>
              <Spacer size={32} />
              <ButtonWithWidth
                size="l"
                variant="white"
                isBlock
                onClick={noop}
                width={200}
              >
                Подробнее
              </ButtonWithWidth>
            </div>
          </Link>
        </Column>
      );
    };

    return (
      <Row className={styles.rowGap}>
        {categories.map(renderHomepageCategory)}
      </Row>
    );
  },
);
