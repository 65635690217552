'use client';
import { Subscription } from 'components/subscription';
import { SubscriptionTag, carrotService } from 'lib';
import { useCallback } from 'react';

export const HomepageSubscription = (): JSX.Element => {
  const handleSubscribe = useCallback((email: string) => {
    return carrotService.subscribePromo(email, SubscriptionTag.SITE_FOOTER);
  }, []);
  return <Subscription onSubscribe={handleSubscribe} />;
};
